<img
  ngSrc="logos/logo-light.svg"
  [loaderParams]="{ type: 'staticImage' }"
  alt="RateMyAgent"
  width="156"
  height="46"
  disableOptimizedSrcset />

<nav class="flex justify-between max-w-[152px]">
  @for (social of socialLinks; track social.href) {
    <a
      class="hover:!text-white text-steel-40 text-small transition-color"
      [href]="social.href"
      [attr.aria-label]="social.label"
      rma-icon-button
      target="_blank"
      rel="noopener">
      <rma-icon [glyph]="social.icon" />
    </a>
  }
</nav>

@if (details) {
  <dl class="text-steel-40 text-bold" *transloco="let t; read: 'footer'">
    @if (details.salesNumber; as salesNumber) {
      <dt>{{ t('contact.sales') }}</dt>
      <dd class="mb-2">{{ salesNumber }}</dd>
    }
    @if (details.supportNumber; as supportNumber) {
      <dt>{{ t('contact.support') }}</dt>
      <dd class="mb-2">{{ supportNumber }}</dd>
    }
    @if (details.supportEmail; as supportEmail) {
      <dt>{{ t('contact.email') }}</dt>
      <dd class="mb-2">{{ supportEmail }}</dd>
    }
    @if (details.trainingWebinarUrl; as trainingWebinarUrl) {
      <dt>{{ t('contact.webinar.label') }}</dt>
      <dd class="mb-2">
        <a [href]="trainingWebinarUrl" target="_blank" rel="noopener">{{ t('contact.webinar.link') }}</a>
      </dd>
    }
    @if (details.intercom) {
      <dt>{{ t('contact.intercom.label') }}</dt>
      <dd>
        <button (click)="openIntercom()" type="button">{{ t('contact.intercom.link') }}</button>
      </dd>
    }
  </dl>
}
