import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Environment } from '@rma/generic/util/environment';

@Component({
  selector: 'rma-facebook-pixel',
  standalone: true,
  templateUrl: './facebook-pixel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookPixelComponent {
  private readonly env = inject(Environment);

  protected pixelUrl = this.getPixelUrl(this.env.tracking.facebookPixelId);

  private getPixelUrl(pixelId?: string): string | null {
    return pixelId ? `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1` : null;
  }
}
