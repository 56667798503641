import { Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { FacebookPixelComponent } from '@rma/generic/ui/facebook-pixel';
import { LazyLoadThirdPartyScriptsService } from '@rma/site/public/orc-layout';

@Component({
  selector: 'rma-landing-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [MatProgressSpinnerModule, RouterOutlet, TranslocoPipe, FacebookPixelComponent],
  providers: [LazyLoadThirdPartyScriptsService],
})
export class AppComponent implements OnInit {
  public isLoading: boolean;

  constructor(
    private readonly router: Router,
    _lazyLoad3rdPartyScripts: LazyLoadThirdPartyScriptsService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.router.events.subscribe((e: Event) => this.setLoading(e));
  }

  private setLoading(event: Event): void {
    if (event instanceof NavigationStart) {
      this.isLoading = true;
    }
    if (event instanceof NavigationEnd) {
      this.isLoading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.isLoading = false;
    }
    if (event instanceof NavigationError) {
      this.isLoading = false;
    }
  }
}
