<a class="inline-flex items-center" routerLink="/">
  @let loaderParams = { type: 'staticImage' };

  <img
    class="md:hidden"
    ngSrc="/logos/logo-compact-dark.svg"
    alt="RateMyAgent"
    width="32"
    height="36"
    priority
    [loaderParams]="loaderParams"
    disableOptimizedSrcset />
  <img
    class="hidden md:block xl:hidden"
    ngSrc="/logos/logo-stacked.svg"
    alt="RateMyAgent"
    width="64"
    height="36"
    priority
    [loaderParams]="loaderParams"
    disableOptimizedSrcset />
  <img
    class="hidden xl:block"
    ngSrc="/logos/logo-dark.svg"
    alt="RateMyAgent"
    width="120"
    height="36"
    priority
    [loaderParams]="loaderParams"
    disableOptimizedSrcset />
</a>
