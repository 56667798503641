<rma-facebook-pixel />

@if (showHeader$ | async) {
  <rma-site-header [basicHeader]="basicHeader$ | async" [fullWidthHeader]="fullWidthHeader$ | async" />
}
<section class="rma-content" [class.!pt-0]="(showHeader$ | async) === false">
  <div class="rma-loader" [class.visible]="isLoading" [class.invisible]="!isLoading">
    @if (isLoading) {
      <mat-spinner />
    }
  </div>

  <router-outlet />

  @if (showFooter$ | async) {
    <rma-footer class="nc-container" />
  }
</section>
