import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrentUserService } from '@rma/accounts/user';
import { RmaUrlsService } from '@rma/generic/util/environment';
import { rmaShareReplay } from '@rma/generic/util/operators/combined';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ChatApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly rmaUrls: RmaUrlsService,
    private readonly userService: CurrentUserService,
  ) {}

  private getUserChatData(): Observable<Record<string, unknown>> {
    return this.httpClient.get<Record<string, unknown>>(this.rmaUrls.apiUrl('Chat/Data'));
  }

  public get userChatData$(): Observable<Record<string, unknown>> {
    return this.userService.currentUser$.pipe(
      map((x) => x?.userId),
      distinctUntilChanged(),
      switchMap(() => this.getUserChatData()),
      rmaShareReplay(),
    );
  }
}
