import { Injectable, NgZone } from '@angular/core';
import { UserTrackerService } from '@rma/generic/util/tracking/analytics';
import { IntercomService } from '@rma/generic/util/tracking/intercom';

@Injectable()
export class LazyLoadThirdPartyScriptsService {
  public constructor(
    private readonly intercom: IntercomService,
    private readonly userTrackerService: UserTrackerService,
    private readonly zone: NgZone,
  ) {}

  public initialize() {
    this.zone.runOutsideAngular(() => {
      this.intercom.initialise();
      this.userTrackerService.watchUser();
    });
  }
}
