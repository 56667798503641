import { LowerCasePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, computed, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { RmaIconComponent } from '@rma/generic/ui/icon';
import { PlatformService } from '@rma/generic/util/environment';
import { LocationFooterLink } from '../data-access/footer-api.service';

@Component({
  selector: 'rma-footer-location-links',
  templateUrl: './footer-location-links.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterModule, MatButtonModule, RmaIconComponent, TranslocoModule, LowerCasePipe, NgClass],
})
export class FooterLocationLinksComponent implements OnChanges {
  @Input()
  locationLinks: LocationFooterLink[] = [];

  @Input({ required: true })
  verticalType!: 'sales' | 'leasing' | 'finance';

  @Input()
  isIndividual = false;

  @Input()
  isMultiColumn = false;

  private readonly links = signal<LocationFooterLink[]>([]);
  private readonly defaultLimit = 8; // australian states + NT

  protected readonly limitTo = signal<number>(0);

  protected readonly hasMore = computed(() => {
    const limitTo = this.limitTo() ?? 0;
    const links = this.links();

    return links.length > limitTo;
  });

  constructor(private readonly platformService: PlatformService) {
    this.limitTo.set(this.platformService.isPlatformServer ? 0 : this.defaultLimit);
  }

  // can remove when we use signal inputs
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['locationLinks'] && this.locationLinks) {
      this.links.set(this.locationLinks);
    }
  }

  public toggleLimit() {
    this.limitTo.update((limitTo) => (limitTo ? 0 : this.defaultLimit));
  }
}
