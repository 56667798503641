import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'promoted',
    children: [
      {
        path: 'listing',
        loadChildren: () => import('@rma/promoter/landing/listing/shell'),
      },
      {
        path: 'award',
        loadChildren: () => import('@rma/promoter/landing/awards/shell-page'),
      },
      {
        path: 'review',
        loadChildren: () => import('@rma/promoter/landing/review/shell-page'),
      },
      {
        path: 'searchad',
        loadChildren: () => import('@rma/promoter/landing/search/shell-page'),
      },
      {
        path: 'appraisal',
        loadChildren: () => import('@rma/promoter/landing/appraisal/shell-page'),
      },
    ],
  },
  {
    path: 'promoter/one-click-start-promotion',
    loadChildren: () => import('@rma/promoter/landing/thank-you/shell-thank-you-page'),
  },
  // Error Pages
  {
    path: '',
    data: {
      hideBackButton: true,
    },
    loadChildren: () => import('@rma/generic/fallback-pages'),
  },
];
