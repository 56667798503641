<rma-facebook-pixel />
@if (isLoading) {
  <div class="bg-steel-5 flex h-screen items-center justify-center">
    <div class="w-4/5 text-center sm:w-auto">
      <mat-spinner class="my-8" />
      <h2 class="my-8 text-base font-medium sm:text-lg">{{ 'landing.loading.spinner.label' | transloco }}</h2>
    </div>
  </div>
}
<router-outlet />
