import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CurrentUserService } from '@rma/accounts/user';
import { Campaign, PrefillFormDetails } from '@rma/campaigns/private/domain-track-campaign';
import { RmaUrlsService } from '@rma/generic/util/environment';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiTrackPropertyStorageService } from './api-track-property-storage.service';
import { LocalTrackPropertyStorageService } from './local-track-property-storage.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TrackPropertyService {
  private readonly campaignCodes = new BehaviorSubject<string[]>([]);
  private readonly userViewedTrackFormKey = 'TrackProperty.{UserId}.HasViewedTrackForm';
  private readonly userDetailsKey = 'TrackProperty.PrefillFormDetails';

  public trackPropertyCampaignCodes$: Observable<string[]>;
  public trackPropertyCount$: Observable<number>;
  public trackPropertyCampaignDetails$: Observable<Campaign[]>;

  public constructor(
    private readonly apiStoreService: ApiTrackPropertyStorageService,
    private readonly httpClient: HttpClient,
    private readonly localStoreService: LocalTrackPropertyStorageService,
    private readonly rmaUrls: RmaUrlsService,
    private readonly userService: CurrentUserService,
  ) {
    this.loadTrackedPropertyCodes(true);
    this.trackPropertyCampaignCodes$ = this.campaignCodes.asObservable();
    this.trackPropertyCount$ = this.trackPropertyCampaignCodes$.pipe(map((x) => x.length));
    this.trackPropertyCampaignDetails$ = this.trackPropertyCampaignCodes$.pipe(
      switchMap((codes) => (codes.length ? this.getCampaignData(codes) : of([]))),
    );
  }

  public trackProperty(campaignCode: string, payload: { firstName: string; email: string }): Observable<string> {
    const trackProperty$ = this.apiStoreService.trackProperty(campaignCode, payload);

    return combineLatest([trackProperty$, this.userService.isAuthenticated$]).pipe(
      map(([trackedProperty, isAuthenticated]) => {
        if (!isAuthenticated) {
          this.localStoreService.add(campaignCode, trackedProperty.trackId);
        }
        this.loadTrackedPropertyCodes(true);
        return trackedProperty.trackId;
      }),
    );
  }

  public untrackProperty(campaignCode: string): void {
    this.userService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.apiStoreService.untrackPropertyByCampaignCode(campaignCode).subscribe(() => this.loadTrackedPropertyCodes(true));
      } else {
        const trackId = this.localStoreService.getTrackId(campaignCode);
        if (trackId) {
          this.apiStoreService.untrackPropertyByTrackId(trackId);
          this.localStoreService.remove(trackId);
        }

        this.loadTrackedPropertyCodes(true);
      }
    });
  }

  public isTracked(campaignCode: string): Observable<boolean> {
    return this.trackPropertyCampaignCodes$.pipe(map((codes) => codes.indexOf(campaignCode) !== -1));
  }

  public hasUserViewedTrackForm(userId: string): boolean {
    const key = this.userViewedTrackFormKey.replace('{UserId}', userId);
    return this.localStoreService.getFromLocalStorage<boolean>(key) === true;
  }

  public setUserViewedTrackForm(userId: string) {
    const key = this.userViewedTrackFormKey.replace('{UserId}', userId);
    this.localStoreService.setToLocalStorage(key, true);
  }

  public getUserDetails(): PrefillFormDetails | null {
    return this.localStoreService.getFromLocalStorage<PrefillFormDetails>(this.userDetailsKey);
  }

  public setUserDetails(value: PrefillFormDetails) {
    return this.localStoreService.setToLocalStorage(this.userDetailsKey, value);
  }

  private loadTrackedPropertyCodes(forceRefresh: boolean): void {
    // set this.trackCampaignCodes and therefore update this.trackCampaignCodes$
    this.userService.isAuthenticated$.pipe(untilDestroyed(this)).subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.apiStoreService
          .getTrackedPropertyCodes(forceRefresh)
          .pipe(untilDestroyed(this))
          .subscribe((x) => (this.trackCampaignCodes = x));
      } else {
        this.trackCampaignCodes = this.localStoreService.getTrackedPropertyCodes();
      }
    });
  }

  private get trackCampaignCodes(): string[] {
    return this.campaignCodes.getValue();
  }

  private set trackCampaignCodes(val: string[]) {
    this.campaignCodes.next(val);
  }

  public getCampaignData(campaignCodes: string[]): Observable<Campaign[]> {
    const url = this.rmaUrls.apiUrl('Sales/Campaigns/Preview');

    return this.httpClient.get<Campaign[]>(url, { params: new HttpParams({ fromObject: { campaignCodes } }) });
  }
}
